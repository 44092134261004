import React from 'react';
import PropTypes from 'prop-types';
import { getFunName } from "../helpers";

class StorePicker extends React.Component {
    static propTypes = {
        history: PropTypes.object
    };

    myInput = React.createRef();

    goToStore = event => {
        // 1. stop the form from submitting
        event.preventDefault();
        // 2. get the text from that input
        const storeName = this.myInput.current.value;
        // 3. change the page to /store/whatever-they-entered
        console.log("Going to store");
        this.props.history.push(`/store/${storeName}`);
    };
    render() {
        return (
            <form action="" className="store-selector" onSubmit={this.goToStore}>
                <h2>Please Enter A Store</h2>
                <input 
                    type="text" 
                    ref={this.myInput}
                    required 
                    placeholder="Store Name" 
                    defaultValue={ getFunName() }
                />
                <button type="submit">Visit Store →</button>
            </form>   
        );
    }
}

export default StorePicker;

// EXAMPLE OF AN INLINE EVENT HANDLING FUNCTION
// class StorePicker extends React.Component {
//     handleClick() {
//         alert("Heyyyyy!");
//     }
//     render() {
//         return (
//             <form action="" className="store-selector">
//                 <h2>Please Enter A Store</h2>

//                 <button onClick={this.handleClick}>Click me!</button>

//                 <input type="text" required placeholder="Store Name" defaultValue={ getFunName() }/>
//                 <button type="submit">Visit Store →</button>
//             </form>   
//         )
//     }
// }

// export default StorePicker;