import React from "react";
import PropTypes from "prop-types";

const Header = props => (
    <header className="top">
        <h1>
            Catch 
            <span className="ofThe">
                <span className="of">Of</span>
                <span className="the">The</span>
            </span>
            Day
        </h1>
        <h3 className="tagline">
            <span>{props.tagline}</span>
        </h3>
    </header>
);

// propTypes has to go outside stateless functions (it goes inside regular components)
Header.propTypes = {
    tagline: PropTypes.string.isRequired
};

// EXAMPLE OF SPECIFYING THE PROPS
// const Header = ({tagline, age}) => (
//     <header className="top">
//         <h1>
//             Catch 
//             <span className="ofThe">
//                 <span className="of">Of</span>
//                 <span className="the">The</span>
//             </span>
//             Day
//         </h1>
//         <h3 className="tagline">
//             <span>{tagline} {age}</span>
//         </h3>
//     </header>
// );

// EXAMPLE OF HOW THIS COMPONENT WITH ONLY A RENDER METHOD IS THE SAME AS ABOVE STATELESS FUNCTIONS
// class Header extends React.Component {
//     render() {
//         return (
//             <header className="top">
//                 <h1>
//                     Catch 
//                     <span className="ofThe">
//                         <span className="of">Of</span>
//                         <span className="the">The</span>
//                     </span>
//                     Day
//                 </h1>
//                 <h3 className="tagline">
//                     <span>{this.props.tagline}</span>
//                 </h3>
//             </header>
//         );
//     }
// }

export default Header;