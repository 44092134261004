import React from "react";
import PropTypes from "prop-types";
import {formatPrice} from "../helpers";

class Fish extends React.Component {
    // if we were to write a handleClick function vs doing it in-line:
    // handleClick = () => {
    //     this.props.addToOrder(this.props.index);
    // };

    // static because this doesn't need to get copied for all Fish; they'll all be the same
    static propTypes = {
        details: PropTypes.shape({
            image: PropTypes.string,
            name: PropTypes.string,
            desc: PropTypes.string,
            status: PropTypes.string,
            price: PropTypes.number
        }),
        addToOrder: PropTypes.func
    };
    render() {
        const {image, name, desc, price, status} = this.props.details;
        const isAvailable = status === 'available';
        return (
            <li className="menu-fish">
                <img src={image} alt={name} className=""/>
                <h3 className="fish-name">
                    {name}
                    <span className="price">{formatPrice(price)}</span>
                </h3>
                <p>{desc}</p>
                {/* using external mdthod vs inline */}
                {/* <button disabled={!isAvailable} onClick={this.handleClick}>{isAvailable? 'Add To Cart' : 'Sold Out!'}</button> */}
                <button disabled={!isAvailable} onClick={ () => this.props.addToOrder(this.props.index)}>{isAvailable? 'Add To Cart' : 'Sold Out!'}</button>
            </li>
        );
    }
}

export default Fish;