import Rebase from 're-base';
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBzC_kHD1KOwxNjRaN_m9HsxAs91tdu--k",
    authDomain: "catch-of-the-day-elle.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-elle.firebaseio.com",
    projectId: "catch-of-the-day-elle",
    // storageBucket: "",
    // messagingSenderId: "600144662107",
    // appId: "1:600144662107:web:f8d51ee6a40edcfd"
  });

  const base = Rebase.createClass(firebaseApp.database());

  // This is a named export
  export { firebaseApp }

  // This is a default export
  export default base;