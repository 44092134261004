import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Order from "./Order";
import Inventory from "./Inventory";
import sampleFishes from "../sample-fishes";
import Fish from "./Fish";
import base from "../base";

class App extends React.Component {
    state = {
        fishes: {},
        order: {}
    };

    static propTypes = {
        match: PropTypes.object
    };

    componentDidMount() {
        const { params } = this.props.match;
        // first reinstate localStorage
        const localStorageRef = localStorage.getItem(params.storeId);
        // console.log("Restoring order")
        // console.log(JSON.parse(localStorageRef))
        if(localStorageRef) {
            this.setState({ order: JSON.parse(localStorageRef)});
        };
        // console.log(params);
        // sync app state with firebase for the specific store, make the inventory persist
        this.ref = base.syncState(`${params.storeId}/fishes`, {
            context: this,
            state: 'fishes'
        }); 
    };
    componentDidUpdate() {
        const { params } = this.props.match;
        // console.log(this.state.order);
        // console.log("It updated!");
        // store the order info in local storage, rather than firebase
        localStorage.setItem(params.storeId, JSON.stringify(this.state.order));
    };
    componentWillUnmount() {
        base.removebinding(this.ref)
    };
    addFish = fish => {
        // console.log("Adding a fish!")
        // 1. Take a copy of the existing state
        const fishes = { ...this.state.fishes };
        // 2. Add our new fishes to that fishes variable
        fishes[`fish${Date.now()}`] = fish;
        // 3.Set the new fishes object to state (set state sets whatever pieces of the state you want, doesn't have to be all the pieces)
        // long version of below, but can compress because they have the same name
        // this.setState({
        //     fishes: fishes
        // });
        this.setState({fishes});
    };
    updateFish = (key, updatedFish) => {
        // 1. Take a copy of the current state
        const fishes = { ...this.state.fishes };
        // 2. Update that state
        fishes[key] = updatedFish;
        // 3. Set the updated state
        this.setState({fishes});
    };
    deleteFish = (key) => {
        // 1. Take a copy of the current state
        const fishes = { ...this.state.fishes };
        // 2. Update the state (remove an item)
        fishes[key] = null;
        // 3. Set the updated state
        this.setState({fishes})
    };
    loadSampleFishes = () => {
        this.setState({
            fishes: sampleFishes
        })
    };
    addToOrder = key => {
       // 1. Take a copy of the existing state
        const order = {...this.state.order};
       // 2. Either add to the order or update the number in our order
       order[key] = order[key]+1 || 1;
       // 3.  Call setState to update our state object
       this.setState({order});
    };
    removeFromOrder = key => {
        // 1. Take a copy of the current state
        const order = {...this.state.order};
        // console.log ("Removing from order!")
        // console.log (order);
        // console.log (order[key]);
        // 2. Remove the fish from the order or reduce the number in our order
        if (order[key] === 1 ) {
            // console.log("if statement triggered")
            delete order[key];
        } else {
            order[key] = order[key]-1
        };
        // console.log(order[key]);
        // 3. Update our state
        this.setState({order});
    };

    render() {
        return (
            <div className="catch-of-the-day">
                <div className="menu">
                    <Header tagline="Fresh Seafood Market" />
                    <ul className="fishes">
                        {Object.keys(this.state.fishes).map(key => (
                            <Fish 
                                key={key} 
                                index={key}
                                details={this.state.fishes[key]}
                                addToOrder={this.addToOrder}
                            />
                        ))}
                    </ul>
                </div>
                <Order 
                    // long, but precise, way of passing the entire state, piece by piece
                    fishes={this.state.fishes}
                    order={this.state.order}
                    removeFromOrder={this.removeFromOrder}
                    // short way of passing the entire state, using object expand -- not best practice, only pass the data you explicitly need
                    // {...this.state}
                />
                <Inventory 
                    addFish={this.addFish} 
                    updateFish={this.updateFish}
                    deleteFish={this.deleteFish}
                    loadSampleFishes={this.loadSampleFishes}
                    fishes={this.state.fishes}
                    storeId={this.props.match.params.storeId}
                />
            </div>
        );
    }
}

export default App;