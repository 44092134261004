import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../helpers";
import { TransitionGroup, CSSTransition } from "react-transition-group"; // for animations

class Order extends React.Component {
    static propTypes = {
        removeFromOrder: PropTypes.func,
        fishes: PropTypes.objectOf(
            PropTypes.shape({
              image: PropTypes.string,
              name: PropTypes.string,
              desc: PropTypes.string,
              status: PropTypes.string,
              price: PropTypes.number
            })
        ),
        // fishes: PropTypes.arrayOf(
        //     PropTypes.objectOf(
        //         PropTypes.shape({
        //         image: PropTypes.string,
        //         name: PropTypes.string,
        //         desc: PropTypes.string,
        //         status: PropTypes.string,
        //         price: PropTypes.number
        //         })
        //     )
        // ),
        // order: PropTypes.objectOf(
        //     PropTypes.shape({
        //         count: PropTypes.number
        //     })
        // )
        // order: PropTypes.object
         order: PropTypes.objectOf(PropTypes.number)
        // order: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number))
    };
    // handleReduce = key => {
    //     console.log ("Reducing order - order level");
    // };
    renderOrder = key => {
        const fish = this.props.fishes[key];
        const count = this.props.order[key];
        const isAvailable = fish && fish.status === 'available';
        const orderItem = this.props.order[key];
        const onlyOne = orderItem === 1;
        const buttonSymbol = `${onlyOne ? '\u00d7' :'\u2212'}`;
        const transitionOptions = {
            classNames: "order", 
            key,
            timeout: { enter: 500, exit:  500}
        };

        // Make sure the fish are loaded before we continue
        if (!fish) return null;
        if (!isAvailable) {
            return (
                // <CSSTransition 
                //     classNames="order" 
                //     key={key}
                //     timeout={{ enter: 500, exit:  500}}
                // >
                // replaced above with a const object and spreading of that object below, since this is used in 2 places
                <CSSTransition {...transitionOptions}>
                    <li key={key}>
                        Sorry {fish ? fish.name : 'fish'} is no longer available
                    </li>
                </CSSTransition>
            );
        }
        return (
            // <CSSTransition 
            //     classNames="order" 
            //     key={key}
            //     timeout={{ enter: 500, exit:  500}}
            // >
            // replaced above with a const object and spreading of that object below, since this is used in 2 places
            <CSSTransition {...transitionOptions}>
                <li key={key}>
                    <span>
                        <TransitionGroup component="span" className="count">
                            <CSSTransition
                                classNames="count"
                                key={count}
                                timeout={{enter: 250, exit: 250}}
                            >
                                <span>{count}</span>
                            </CSSTransition>
                        </TransitionGroup>
                        lbs {fish.name}
                        {formatPrice(count * fish.price)}
                        {/* {console.log({buttonSymbol})} */}
                        <button onClick={() => this.props.removeFromOrder(key)} >
                            {buttonSymbol}
                        </button>
                        {/* <button onClick={this.handleReduce}>Reduce Order</button> */}
                    </span>
                </li>
            </CSSTransition>
        );
    };
    render() {
        const orderIds = Object.keys(this.props.order);
        const total = orderIds.reduce((prevTotal,key) => {
            const fish = this.props.fishes[key];
            const count = this.props.order[key];
            const isAvailable = fish && fish.status === 'available';
            if(isAvailable) {
                return prevTotal + (count * fish.price);
            }
            return prevTotal;
        }, 0);
        return (
            <div className="order-wrap">
                <h2>Order</h2>
                    <TransitionGroup component="ul" className="order">
                        {orderIds.map(this.renderOrder)}
                    </TransitionGroup>
                <div className="total">
                    Total:
                    <strong>{formatPrice(total)}</strong>
                </div>
            </div>
        );
    }
}

export default Order;